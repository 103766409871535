// Main SASS file (compiled by Jekyll)
@import 'foundation/settings';
@import 'foundation/components/global';

// Github-like CODE format (A.Chamberlin)
//code {
//  padding: 0.2em;
//  margin: 0;
//  background-color: rgba(0,0,0,0.04);
//  border-radius: 3px;
//}

// Add borders between all foundation table cells (A.Chamberlin)
//table tbody td, table thead th {
//  border: solid 1px $gainsboro;
//}

// Prevent text-selection:
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// Watermark (for non-public versions of the site):
.watermark {
  position: fixed;
  top: 90px;
  right: 20px;
  font-size: 3em;
  font-style: italic;
  color: lightgray;
  opacity: 0.5;
  z-index: 99;
  pointer-events: none;
}

// Custom alert box for site messages:
.site-wide-alert {
  font-size: 1.2rem;
  text-align: center;
}

// Header/Footer background color:
$head_foot_color: $oil;

// JPL Header Stuff:
.site_header {
  background-color: $head_foot_color;
  height: 80px;
  padding-top: 5px;
  padding-left: 10px;
}

// NOTE: rem-calc() is needed because these logo elements are within other foundation elements
#api_logo img {  // raw size: 141x45 (WxH)
  //min-width: none;
  width: rem-calc(141);
  height: rem-calc(45);
}
#gear_logo {  // raw size: 40x40 (WxH)
  width: rem-calc(30);
  height: rem-calc(30);
}

// Breakpoint for small trio-logo:
$small_logo_width: 500px;

.logo-container {
  padding-top: 5px;
  padding-left: 10px;
  @media only screen and (max-width: $small_logo_width) {
    padding-top: 15px;
  }
}

.nasa-logo {
  float: left;
  position: relative;
  // Raw Size: 724x136px (WxH)
  background-image: url("/images/logo_nasa_trio_white@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  //display: inline-block;
  width: 362px;
  height: 68px;
  @media only screen and (max-width: $small_logo_width) {
    //width: 181px; // 1/4 size
    //height: 34px; // 1/4 size
    width: 250px;
    height: 47px;
  }
}

#logo_link_nasa {
  //border: 1px solid yellow;
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 68px;
  @media only screen and (max-width: $small_logo_width) {
    top: 0px;
    left: 0px;
    width: 56px;
    height: 46px;
  }
}

#logo_link_jpl {
  //border: 1px solid yellow;
  background-color: transparent;
  position: absolute;
  top: 12px;
  left: 84px;
  width: 280px;
  height: 24px;
  @media only screen and (max-width: $small_logo_width) {
    top: 10px;
    left: 58px;
    width: 194px;
    height: 14px;
  }
}

#logo_link_caltech {
  //border: 1px solid yellow;
  background-color: transparent;
  position: absolute;
  top: 39px;
  left: 84px;
  width: 280px;
  height: 21px;
  @media only screen and (max-width: $small_logo_width) {
    top: 26px;
    left: 58px;
    width: 194px;
    height: 14px;
  }
}

.hidden-image {
  display: none;
}

// Page-relative anchors offset to account for fixed-navbar:
a[id]:before,
a[name]:before {
  display: block;
  content: " ";
  margin-top: -80px;
  height: 80px;
  visibility: hidden;
}

// Customize markdown-body styles:
.markdown-body {
  table {
    thead tr {
      background-color: #eee;
    }
    border: none;
  }
}

// JPL Footer Stuff:
$footer_text_color: white;
.site-footer {
  background-color: $head_foot_color;
  //@media #{$medium-only} {
  //  background-color: darkred;
  //}
  //@media #{$small-only} {
  //  background-color: darkgreen;
  //}
  //@media only screen and (max-width: $small_logo_width)
  //{
  //  background-color: $head_foot_color;
  //}
  color: $footer_text_color;
  height: 94px;
  padding-top: 6px;
  padding-left: 20px;
  @media #{$small-only} {
    padding-left: 10px;
  }
}

.footer-links {
  float: left;
  padding-top: 26px;
  padding-left: 20px;
  @media #{$small-only} {
    padding-top: 0px;
    padding-left: 0px;
  }
}
.footer-links ul {
  list-style-type: none;
  @media #{$small-only} {
    padding-left: 0px;
  }
}

.footer-people {
  float: right;
  padding-right: 20px;
  padding-top: 4px;
  @media #{$small-only} {
    padding-right: 0px;
    //padding-top: 0px;
  }
}
.footer-people table {
  background-color: $head_foot_color;
  border: none;
  padding: 0px;
  margin: 0px;
}
.footer-people tr {
  background-color: $head_foot_color;
}
.footer-people table, .footer-people td {
  background-color: $head_foot_color;
  color: $footer_text_color;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 5px;
}
.people-label {
  color: #ccc !important;
}

// Text classes:
.text-nowrap {
  white-space: nowrap;
}

// Blockquote classes:
blockquote.important {
  background-color: lightyellow;
  color: black !important;
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}
